@use "mixins";
@import "reset";

@layer reset, defaults, components;

@layer defaults {
  body {
    font-kerning: auto;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.2;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
  }

  html,
  body {
    height: 100%;
    max-width: 100%;
  }

  .root {
    min-height: 100%;
    display: flex;
    flex-direction: column;
  }

  .main {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
  }
}

.ant-table--cursor {
  tbody tr {
    cursor: pointer;
  }
}

.ant-card-body--contrast {
  .ant-card-body {
    background-color: #f2f2f2;
  }
}

@keyframes lock-move {
  from {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-30px);
  }

  to {
    transform: translateY(0);
  }
}

.main {
  height: 100vh;
}

.fox-logo {
  position: relative;
  top: 20px;
  max-width: 100%;
  height: 80vh;
  border-radius: 16px;
  object-fit: cover;
  margin-right: 20px;
}

.tab-op .ant-tabs-tab {
  margin-left: 10px !important;
}

.tab-op {
  min-width: 85%;
}

.nowrap {
  flex-wrap: nowrap !important;
}

.sidebar-wrap {
  width: 200px !important;
}

.ant-menu-item {
  padding: 0 !important;
  padding-left: 16px !important;
}

.pipes-row__check {
  justify-content: start !important;
}

.vertical-middle {
  vertical-align: middle;
}

.ant-menu-item-icon {
  margin: 0 auto;
}

.green {
  color: green;
  font-weight: 700;
}

.cell--red {
  background-color: red;
  color: #fff;

  span {
    color: #fff;
  }
}

.tr--cell {
  width: fit-content;
  padding: 5px 8px;
  border-radius: 8px;
}

@media (max-width: 2000px) {
  .table-min table {
    min-width: 1800px !important;
  }
}

// bootstrap queries
@media (max-width: 1400px) {
  .pipes-row__check {
    margin-top: 10px;
  }

  .sidebar-wrap {
    min-width: 200px !important;
  }

  .pipes-row {
    flex-wrap: wrap !important;
  }
}

.main-content {
  height: 100vh;
  width: 100%;
  overflow-y: scroll;
  padding-bottom: 40px;
}

html,
body {
  overflow-y: hidden;
}

.table-main {
  margin-bottom: 20px;
}

.lottery-user {
  display: flex;
  gap: 50px;
  margin: 30px 0;
}

.row {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.sidebar-wrap__switch {
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  padding: 0 30px;
  margin-top: auto;
  gap: 8px;

  span {
    display: block;
    white-space: nowrap;
    margin-bottom: 8px;
  }
}

.row-table {
  display: flex;
  gap: 20px;
}

.card-ip {
  margin-bottom: 20px;
  border-bottom: 1px solid black;

  &:last-child {
    margin-bottom: 0;
    border-bottom: 0;
  }
}

.table-inner {
  border: 1px solid rgba(255, 255, 255, 0.3);

  & .ant-table.ant-table-scroll-horizontal {
    margin-block: 0 !important;
    margin-inline: 0 !important;
    padding: 30px;
  }
}
