@layer reset {
  html, body, div, span, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  abbr, address, cite, code,
  del, dfn, em, img, ins, kbd, q, samp,
  small, strong, sub, sup, var,
  b, i,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
  }

  ul {
    list-style: none;
  }

  html {
    box-sizing: border-box;
  }

  a {
    text-decoration: none;
    outline: 0;

    &:hover, &:active, &:focus, &:visited {
      text-decoration: none;
      outline: 0;
    }
  }

  input, select, textarea {
    font-family: inherit;
    font-weight: 400;
    box-shadow: none;
    -webkit-appearance: none;
    border: 0;
    outline: 0;
    background: #FFF;
    margin: 0;
  }

  button {
    outline: 0;
    border: none;
    box-shadow: none;
    margin: 0;
  }

  button::-moz-focus-inner,
  input::-moz-focus-inner,
  select::-moz-focus-inner {
    border: 0;
    padding: 0;
    outline: 0;
  }

  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }

  svg {
    display: block;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }
}
